import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Slider from 'react-slick';  // Import Slider from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Link, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {ApiParam as ApiParamKey} from '../../api/network/apiParams';
import {fetchProductsChild} from '../../api/redux/products';
import {hover} from "../../styles/globalStyleVars";

const MyComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation(); // Get the current route
    const getData = useSelector((state) => state.productReducer);
    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.PRODUCTS;
        let api_params = {
            [ApiParamKey.page_id]: 10,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.category]: '',
        };
        dispatch(fetchProductsChild([apiUrl, api_params]));
    }, [location.pathname]);


    const [activeClass, setActiveClass] = useState('Air Conditioner');


    const category = getData?.child?.filter?.category;
    const [finalsubcat, setFinalsubcat] = useState();

    const handleClick = (i) => {
        setActiveClass(i?.title)
    };


    const finalcategory = getData?.child?.data?.filter(i => i?.product_data?.category_title === activeClass);

    useEffect(() => {
        const subcatFinal = finalcategory?.length > 0
            ? [...new Set(finalcategory.map(i => i?.product_data?.sub_category))]
            : [];

        // Define the custom order
        const customOrder = ['s', 'd', 'c', 'f', 'p'];

        // Sort based on the custom order
        const sortedSubcatFinal = subcatFinal.sort((a, b) => {
            const indexA = customOrder.indexOf(a?.charAt(0).toLowerCase());
            const indexB = customOrder.indexOf(b?.charAt(0).toLowerCase());

            // If either character is not in the custom order, keep them as is
            return (indexA !== -1 ? indexA : customOrder.length) - (indexB !== -1 ? indexB : customOrder.length);
        });

        setFinalsubcat(sortedSubcatFinal);
    }, [getData, activeClass]);


    const renderItems = (items) => {
        const columns = [];
        const itemsPerSlide = 5; // Number of items per slide
        const slidesToShow = 8; // Number of slides visible at a time

        for (let i = 0; i < items.length; i += itemsPerSlide) {
            columns.push(
                <Col md={3} key={i}>
                    <ul>
                        {items.slice(i, i + itemsPerSlide).map((item) => (
                            <Link to={`/products?subcategory=${item}`}>
                                <li>{item}</li>
                            </Link>
                        ))}
                    </ul>
                </Col>
            );
        }

        return columns;
    };


    // Settings for the slick slider


    const NextArrow = (props) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={className}
                style={{...style, display: 'block'}}
                onClick={onClick}
            >
                Next
            </div>
        );
    };

// Custom component for the previous arrow
    const PrevArrow = (props) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={className}
                style={{...style, display: 'block'}}
                onClick={onClick}
            >
                Prev
            </div>
        );
    };
// Settings for the slick slider
    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 6, // Number of slides visible at a time
        slidesToScroll: 4, // Number of slides to scroll at a time
        nextArrow: <NextArrow/>, // Custom component for next arrow
        prevArrow: <PrevArrow/>, // Custom component for previous arrow
    };

// Custom component for the next arrow

    return (
        <StyledComponent>
            <Tabs>
                {category &&
                    <Slider {...sliderSettings}>
                        {category?.length > 0 &&
                            category?.map((i, index) => (
                                <Tab className={`react-tabs__tab ${activeClass === i?.title ? 'active' : ''}`}
                                     key={index} onClick={() => handleClick(i)}>
                                    {i?.title}
                                </Tab>
                            ))}
                    </Slider>
                }


                {finalsubcat?.length > 0 &&
                    finalsubcat?.map((i, index) => (
                        <TabPanel key={index}>
                            <Container fluid>
                                <Row>{renderItems(finalsubcat)}</Row>
                            </Container>
                        </TabPanel>
                    ))}
            </Tabs>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .container-fluid {
    padding: unset;

    .row {
      width: 80%;
    }
  }

  .slick-slider {
    margin-bottom: 30px;
  }

  .slick-slide {
    li {
      display: inline-flex !important;
      text-align: center;
      border-bottom: 0.5px solid rgb(196, 196, 196);
      height: 53px;
      justify-content: center;
      align-items: center;
    }

    .active {
      background: #6EAFC4;
      color: #fff !important;
      border-right: unset;
    }
  }

  .container-fluid {
    padding: 0px 15px !important;
  }

  .react-tabs__tab-panel {

    li {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(0, 40, 57, 0.8) !important;
      transition: 0.5s ease color;

      &:hover {
        color: rgb(110, 175, 196) !important;
        transition: 0.5s ease color;
      }
    }
  }

  .slick-prev {
    left: -35px;

    &:before {
      color: #002839;
    }
  }

  .slick-next {
    right: -35px;

    &:before {
      color: #002839;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .react-tabs {
      padding: 50px !important;
    }
  }
`;

export default MyComponent;
